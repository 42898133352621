<template>
  <nav class="side-nav side-nav--simple" role="navigation">
    <CategoryListLoader v-if="isFetchingCategories" :categories="5"></CategoryListLoader>
    <ul
      class="list-group list-group-flush side-nav__list side-nav__list--simple text-sm"
    >
      <li
        v-for="(category, i) in categories"
        :key="i"
        :class="{
          'is-active': $route.params.categorySlug === category.url_path
        }"
        class="list-group-item side-nav__item side-nav__item--simple"
      >

        <router-link
          :to="{ name: 'Category', params: { categorySlug : category.url_path} }"
          class="side-nav__link side-nav__link--simple"
        >
          {{ category.name }}
        </router-link>
        <ul
          class="list-group list-group-flush side-nav__list side-nav__list--simple side-nav__list--sub-2"
          v-if="$route.params.categorySlug === category.url_key"
        >
          <li
            class="list-group-item side-nav__item side-nav__item--simple side-nav__item--sub-2"
            v-for="(subcategory, subI) in category.children"
            :key="subI"
            :class="{
              'is-active': $route.params.subCategorySlug === subcategory.url_key
            }"
          >
            <router-link
              :to="{ name: 'SubCategory', params: { categorySlug : category.url_path, subCategorySlug : subcategory.url_key} }"
              class="side-nav__link side-nav__link--simple side-nav__link--sub-2"
            >
              {{ subcategory.name }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
    <ul
      v-if="products && products.aggregations"
      class="list-group list-group-flush side-nav__list side-nav__list--facet text-sm d-none d-lg-block"
    >
      <template
        v-for="(aggregation, key) in products.aggregations"
        :key="'aggregation-' + key"
      >
        <li
          class="list-group-item side-nav__item side-nav__item--facet"
          v-if="!['category_id', 'price'].includes(aggregation.attribute_code)"
        >
          <a
            href="#facet-1"
            class="side-nav__link side-nav__link--facet btn-dropdown"
            data-toggle="collapse"
            role="button"
            aria-expanded="true"
            aria-controls="facet-1"
          >
            <strong>{{ aggregation.label }}</strong>
          </a>
          <div class="collapse show" id="facet-1" style="">
            <div class="collapse__body text-xs pt-3">
              <div
                class="custom-control custom-check"
                v-for="(option, optionKey) in aggregation.options"
                :key="'aggregation-' + key + '-' + optionKey"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="demo-other"
                  :id="aggregation.attribute_code + '-' + option.value"
                  :value="option.value"
                  :checked="
                    this.filters[aggregation.attribute_code] &&
                      this.filters[aggregation.attribute_code].includes(
                        option.value
                      )
                  "
                  @change="
                    changeFilter(
                      $event,
                      aggregation.attribute_code,
                      option.value
                    )
                  "
                />
                <label
                  class="custom-control-label"
                  :for="aggregation.attribute_code + '-' + option.value"
                >
                  {{ option.label }} ({{ option.count }})
                </label>
              </div>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  // props: ["categories"],
  components: {
  },

  data() {
    return {
      filters: {}
    };
  },
  name: "categories",
  created() {
    for (let [key, value] of Object.entries(this.$route.query)) {
      this.filters[key] = value;
    }
  },
  computed: {
    ...mapGetters(["categories", "isFetchingCategories"]),
    products: function() {
      if (
        this.category &&
        this.category.id &&
        this.$store.getters.products[this.category.id]
      ) {
        return this.$store.getters.products[this.category.id];
      } else {
        return null;
      }
    },
    category: function() {

      if (
        this.$route.params.subCategorySlug &&
        this.$store.getters.categories[this.$route.params.categorySlug] !==
          undefined
      ) {
        return this.$store.getters.categories[this.$route.params.categorySlug]
          .children[this.$route.params.subCategorySlug];
      } else if (
        this.$route.params.categorySlug &&
        this.$store.getters.categories[this.$route.params.categorySlug] !==
          undefined
      ) {
        return this.$store.getters.categories[this.$route.params.categorySlug];
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions(["fetchProducts"]),
    changeFilter: function(e, attribute_code, value) {
      if (this.filters.p) delete this.filters.p;
      if (this.filters[attribute_code]) {
        let array = this.filters[attribute_code].split(",");
        if (array.includes(value) && !e.target.checked) {
          let index = array.indexOf(value);
          index > -1 ? array.splice(index, 1) : "";
        } else {
          array.push(value);
        }
        this.filters[attribute_code] = array.join();
        if (this.filters[attribute_code] === "")
          delete this.filters[attribute_code];
      } else {
        this.filters[attribute_code] = value;
      }

      this.$router.replace({
        name: this.$route.name,
        query: this.filters
      });

      this.fetchProducts({
        categoryId: this.category.id,
        filters: this.filters
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
