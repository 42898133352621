<template>
  <section
    class="section"
    v-if="cart && JSON.stringify(cart) != '{}' && cart.items.length > 0"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 order-2 order-lg-1">
          <div class="pt-6">
            <PlaceOrder v-if="authUser.firstname" />
          </div>
        </div>

        <div class="col-lg-6 order-1 order-lg-2">
          <div class="sticky">
            <div class="box">
              <div class="box__body bg-light">
                <div class="box__content">
                  <h3 v-t="'order.order-summary'">
                    Order summary
                  </h3>
                  <div class="d-flex align-items-center text-sm">
                    <span class="mr-3"> {{ cartCount }} products </span>
                    <router-link
                      :to="{ name: 'Cart' }"
                      class="btn btn-sm btn-empty"
                    >
                      <span v-t="'order.adjust'">
                        Adjust
                      </span>
                    </router-link>

                    <span class="ml-auto" v-t="'miscellaneous.labels.quantity'">
                      Quantity
                    </span>
                  </div>
                  <table class="table table--products w-100">
                    <tr
                      valign="top"
                      v-for="(cartItem, i) in cart.items"
                      :key="i"
                    >
                      <td class="pb-0">
                        <strong
                          >- {{ cartItem.product.name }}
                          <span class="text-xs"
                            >({{ cartItem.product.sku }})
                          </span></strong
                        >
                      </td>
                      <td class="pb-0 pl-2">
                        <strong>{{ cartItem.quantity }}</strong>
                      </td>
                    </tr>
                    <tr class="d-none">
                      <td colspan="2">
                        <div class="single-border"></div>
                      </td>
                    </tr>
                    <tr class="d-none">
                      <td v-t="'order.subtotal'">
                        Subtotal
                      </td>
                      <td>
                        <div class="price">
                          <!-- <ProductPrice
                            v-if="cart.prices"
                            :price="cart.prices.subtotal_excluding_tax"
                          ></ProductPrice> -->
                        </div>
                      </td>
                    </tr>

                    <!-- Removed for now -->
                    <tr class="d-none">
                      <td v-t="'order.shipping-handling'">
                        Shipping & handling
                      </td>
                      <td>
                        <div class="price">
                          <!-- Show accessible price to screen readers. -->
                          <span class="sr-only">€ 100</span>

                          <!-- Hide fancy price from screen readers. -->
                          <span
                            class="price__currency text-sm"
                            aria-hidden="true"
                            >€</span
                          >
                          <span class="price__int text-xl" aria-hidden="true"
                            >100</span
                          >
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div class="double-border"></div>
                      </td>
                    </tr>
                    <tr>
                      <td v-t="'order.request-total'">
                        Request total
                      </td>
                      <td>
                        <div class="price">
                          <ProductPrice
                            v-if="cart.prices"
                            :price="this.finalPrice"
                          ></ProductPrice>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <!--
              Only used for quote

            -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section products products--empty" v-else>
    <div class="container text-center">
      <p v-t="'shop.cart.empty'">
        Your cart is empty. Visit the webshop to add products to your cart.
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ProductPrice from "../../components/Products/ProductPrice.vue";
import PlaceOrder from "../../components/Forms/PlaceOrder.vue";

export default {
  components: {
    ProductPrice,
    PlaceOrder
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["cart", "isLoggedIn", "cartCount", "authUser"]),
    finalPrice() {
      if (this.cart && this.cart.items.length > 0) {
        let total = { value: 0 };
        this.cart.items.forEach(function(item, index) {
          let product_price = item.product.customer_price.final_price.value;
          let product_currency =
            item.product.customer_price.final_price.currency;
          if (typeof product_price == "number") {
            total.value += product_price * Number(item.quantity);
          }
          total.currency = product_currency;
        });

        return total;
      } else {
        return "";
      }
    }
  },
  created() {
    // console.log(this.authUser);
    // this.localUser = JSON.parse(JSON.stringify(this.authUser));
    // console.log("Local user", this.localUser);
    // console.log("s");
    // if (this.authUser) {
    // this.form.billing_address.firstname = this.authUser.firstname;
    // }
    // this.form.billing_address.lastname = this.authUser.lastname;
  }
};
</script>

<style lang="css" scoped></style>
