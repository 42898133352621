<template>
  <section class="section cards cards--default">
    <div class="section__header mb-4 cards__header">
      <div
        class="container d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between"
      >
        <div class="d-flex align-items-center mr-md-4">
          <h3 class="section__title cards__title">
            Support & manuals
          </h3>
        </div>
      </div>
    </div>
    <div class="section__body cards__body">
      <div class="container">
        <div class="row row--col-margin-small row--col-margin-md-medium">
          <div
            class="col-12 col-md-4"
            v-for="(supportItem, i) in supportItems"
            :key="i"
          >
            <SupportItem :supportItem="supportItem"></SupportItem>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SupportItem from "./SupportItem.vue";

export default {
  props: {
    supportItems: {
      type: Object
    }
  },
  components: {
    SupportItem
  },
  created() {}
};
</script>

<style lang="css" scoped></style>
