<template>
    <section>
      <div class="section__body cards__body">
        <div class="container">
          <div class="row row--col-margin-medium" >
            <div :class="classes" v-for="(category, i) in categories" :key="i">
              <ContentLoader
                viewBox="0 0 240 200"
                style="width: 100%;"
              >
                  <rect x="0" y="0" width="240" height="160" />
                  <rect x="60" y="180" width="120" height="20" />
              </ContentLoader>
            </div>

          </div>
        </div>
      </div>
    </section>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
  components: {
    ContentLoader
},
  props: {
    categories: {
        type: Number,
        required: (true),
        default: 0
    },
    classes: {
        type: String,
        required: (true),
        default: "col-6 col-md-4 col-lg-3 col-xl-2"

    }

  },
  data() {
    return {

    };
  },
  created() {

  },
  computed: {

  },

  methods: {

  },
};
</script>

<style lang="css" scoped></style>
