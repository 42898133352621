<template>
  <section class="section shop">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 d-none d-lg-block">
          <div class="sidebar">
            <Nav></Nav>
          </div>
        </div>

        <div class="col-lg-9">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Nav from "./Nav.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Nav
  },
  data() {
    return {
      categoryName: ""
    };
  },
  computed: mapGetters(["categories"]),
  created() {
    this.fetchCategories();
  },
  methods: {
    // Make specified vuex actions available
    ...mapActions(["fetchCategories"])
  }
};
</script>

<style lang="css" scoped></style>
