<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="product-modal"
  >
    <div class="modal-dialog" role="document">
      <div class="container">
        <div class="modal-content">
          <div class="modal-header bg-light flex-column">
            <div class="d-flex w-100">
              <div class="d-flex align-items-center">
                <div class="section__icon">
                  <span class="btn btn-primary btn-icon-only">
                    <i class="icon icon-done"></i>
                  </span>
                </div>

                <h4
                  class="section__title cards__title mb-0"
                  v-t="'shop.cart-popup.title'"
                >
                  {{ $t("shop.cart.product-added") }} {{ cartType }}
                </h4>
              </div>

              <div
                class="ml-auto flex-shrink-0 d-none d-md-block btn-group btn-group-lg"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-empty btn-empty-primary"
                  @click="dismissModal()"
                >
                  <span v-t="'shop.cart-popup.continue'">
                    Continue shopping
                  </span>
                </button>

                <router-link
                  :to="{ name: 'Cart' }"
                  class="btn btn-secondary"
                  @click="dismissModal()"
                  v-if="cartType == 'cart'"
                >
                  <span v-t="'shop.cart-popup.view'">
                    View cart
                  </span>
                  <i class="icon icon-shopping_cart"></i>
                </router-link>

                <router-link
                  :to="{ name: 'Quote' }"
                  class="btn btn-secondary"
                  @click="dismissModal()"
                  v-if="cartType == 'quote'"
                >
                  <span v-t="'shop.quote-popup.view'">
                    View Quote
                  </span>
                  <i class="icon icon icon-description"></i>
                </router-link>
              </div>
            </div>

            <span class="d-block product-title h6 mt-3">
              {{ product.name }}
            </span>
          </div>

          <div
            class="modal-body"
            v-if="
              product.crosssell_products &&
                product.crosssell_products.length > 0
            "
          >
            <h4 v-t="'shop.cart-popup.optional'">
              You may also need
            </h4>

            <ul
              class="list-view list-inline d-lg-flex flex-row flex-wrap mb-n4"
            >
              <template
                v-for="(product, i) in product.crosssell_products.slice(0, 4)"
                :key="i"
              >
                <ProductListview3 :product="product"></ProductListview3>
              </template>
            </ul>
          </div>

          <div
            class="modal-footer"
            v-if="
              product.crosssell_products &&
                product.crosssell_products.length > 0
            "
          >
            <div class="mr-auto flex-shrink-0 btn-group btn-group-lg">
              <router-link
                :to="{ name: 'Cart' }"
                class="btn btn-secondary"
                @click="dismissModal()"
                v-if="cartType == 'cart'"
              >
                <span v-t="'shop.cart-popup.view'">
                  View cart
                </span>
                <i class="icon icon-shopping_cart"></i>
              </router-link>

              <router-link
                :to="{ name: 'Quote' }"
                class="btn btn-secondary"
                @click="dismissModal()"
                v-if="cartType == 'quote'"
              >
                <span v-t="'shop.quote-popup.view'">
                  View Quote
                </span>
                <i class="icon icon icon-description"></i>
              </router-link>

              <button
                type="button"
                class="btn btn-sm btn-empty btn-empty-primary"
                @click="dismissModal()"
              >
                <span v-t="'shop.cart-popup.continue'">
                  Continue shopping
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductListview3 from "../../components/Products/ProductListview3.vue";
import Modal from "bootstrap.native/src/components/modal-native";

export default {
  components: {
    ProductListview3
  },
  props: {
    product: {
      type: Object
    },
    show: {
      type: Boolean
    },
    active: {
      type: Boolean
    },
    cartType: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    dismissModal() {
      const productModal = document.getElementById("product-modal");
      const productModalInit = new Modal(productModal);
      productModalInit.hide();
    }
  },
  mounted() {}
};
</script>

<style></style>
