<template>
  <section
    class="section products list list--products"
    v-if="
      !isFetchingCart && JSON.stringify(cart) != '{}' && cart.items.length > 0
    "
  >
    <div class="container">
      <ul class="list-view list-inline">
        <template v-for="(cartItem, i) in cart.items" :key="i">
          <ProductListview2
            :product="cartItem.product"
            :quantity="cartItem.quantity"
            :cartItemId="parseFloat(cartItem.id)"
          ></ProductListview2>
        </template>
      </ul>
      <div class="row">
        <div class="col-lg-5 offset-lg-7">
          <div class="box">
            <div class="box__body bg-white">
              <div class="box__content p-0">
                <table class="table table--products w-100 mt-3">
                  <tbody>
                    <tr>
                      <td v-t="'shop.cart.total'">
                        Total
                      </td>
                      <td>
                        <!-- <ProductPrice
                          v-if="cart.prices"
                          :price="cart.prices.subtotal_excluding_tax"
                        ></ProductPrice> -->

                        <ProductPrice
                          v-if="cart.prices"
                          :price="this.finalPrice"
                        ></ProductPrice>
                      </td>
                    </tr>

                    <!-- Removed for now -->
                    <tr class="d-none">
                      <td v-t="'order.shipping-handling'">
                        Shipping &amp; handling
                      </td>
                      <td>
                        <div class="price"></div>
                      </td>
                    </tr>
                    <tr class="d-none">
                      <td colspan="2">
                        <div class="box my-3">
                          <div class="box__body bg-light">
                            <div class="box__content">
                              <div class="form-group">
                                <label for="number" v-t="'order.country'"
                                  >Country</label
                                >
                                <div class="custom-control custom-selectbox">
                                  <select class="form-control">
                                    <option selected=""
                                      >Open this select menu</option
                                    >
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="number">Country</label>
                                <div class="custom-control custom-selectbox">
                                  <select class="form-control">
                                    <option selected=""
                                      >Open this select menu</option
                                    >
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                              </div>
                              <div class="text-center">
                                <button class="btn btn-secondary">
                                  <span
                                    v-t="'order.estimate-shipping-handling'"
                                  >
                                    Estimate shipping &amp; handling costs
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="d-none">
                      <td colspan="2">
                        <div class="double-border"></div>
                      </td>
                    </tr>
                    <tr class="d-none">
                      <td>
                        Request total
                      </td>
                      <td>
                        <!-- <ProductPrice
                          v-if="cart.prices.applied_taxes.length > 0"
                          :price="cart.prices.applied_taxes.amount"
                        ></ProductPrice> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right">
                  <router-link
                    :to="{ name: 'Checkout' }"
                    class="btn btn-secondary ml-auto"
                  >
                    <span v-t="'shop.cart.proceed'">
                      Proceed to checkout
                    </span>
                    <i class="icon icon-keyboard_arrow_right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section products products--empty" v-else-if="isFetchingCart">
    <div class="container">
      <ContentLoader
        viewBox="0 0 1180 620"
        style="width: 100%;"
      >

        <rect x="0" y="0" width="200" height="50" />
        <rect x="230" y="0" width="200" height="50" />
        <rect x="0" y="75" width="1180" height="200" />
        <rect x="0" y="295" width="1180" height="200" />
      </ContentLoader>
    </div>
  </section>
  <section class="section products products--empty" v-else>
    <div class="container text-center">
      <p v-t="'shop.cart.empty'">
        Your cart is empty. Visit the webshop to add products to your cart.
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex"; //

import ProductListview2 from "../../components/Products/ProductListview2.vue";
import ProductPrice from "../../components/Products/ProductPrice.vue";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ProductListview2,
    ProductPrice,
    ContentLoader
  },
  created() {
    this.fetchDealers();
  },
  methods: {
    ...mapActions(["addProducts", "fetchCustomProducts", "fetchDealers"])
    // dealerChanged(dealerName) {
    //   // Dealer name input
    //   const dealerInputNameEmpty = document.getElementById(
    //     "input-dealer-name-empty"
    //   );

    //   // Remove icon to empty input
    //   dealerInputNameEmpty.classList.add("d-none");

    //   // Add icon to empty input
    //   if (this.dealerName.length !== 0) {
    //     dealerInputNameEmpty.classList.remove("d-none");
    //   }
    // }
  },
  computed: {
    ...mapGetters(["cart", "isLoggedIn", "vetusdealers", "isFetchingCart"]),

    finalPrice() {
      if (this.cart && this.cart.items.length > 0) {
        let total = { value: 0 };
        this.cart.items.forEach(function(item, index) {
          let product_price = item.product.customer_price.final_price.value;
          let product_currency =
            item.product.customer_price.final_price.currency;
          if (typeof product_price == "number") {
            total.value += product_price * Number(item.quantity);
          }
          total.currency = product_currency;
        });

        return total;
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
