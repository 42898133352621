<template>
  <li class="list-item">
    <div class="row">
      <div class="col-3 col-sm-2">
        <div class="list-item__visuals">
          <div class="list-item__media media ratio ratio--square">
            <router-link
              :to="{
                name: 'ProductSingle',
                params: {
                  locale: this.$i18n.locale,
                  productSlug: product.url_key
                }
              }"
              class="media__wrapper"
              tabindex="-1"
              :title="product.name"
            >
              <img :src="product.image.url" :alt="product.name" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-9 col-sm-10">
        <div class="list-item__body">
          <div class="row d-flex">
            <div
              class="list-item__content product-item__content col-md-8 col-xl-9"
            >
              <div
                class="list-item__title product-item__title product-item__title--trunc"
              >
                <router-link
                  :to="{
                    name: 'ProductSingle',
                    params: {
                      locale: this.$i18n.locale,
                      productSlug: product.url_key
                    }
                  }"
                  class="text-subtitle"
                  tabindex="-1"
                  :title="product.name"
                >
                  <span v-html="product.name"></span>
                </router-link>
              </div>
              <div class="list-item__label product-item__sku">
                <span>
                  {{ product.sku }}
                </span>
              </div>
              <div
                class="list-item__desc product-item__desc"
                v-if="product.short_description"
                v-html="product.short_description.html"
              ></div>
            </div>
            <div
              class="product-item__meta col-md-4 col-xl-3 d-flex flex-column"
            >
              <ProductPrice
                v-if="isLoggedIn && product.customer_price != null"
                :price="product.customer_price.final_price"
              ></ProductPrice>

              <ProductStock
                :stock="product.customer_stock"
                v-if="
                  product.customer_stock != null &&
                    product.customer_stock != -1 &&
                    isLoggedIn
                "
              ></ProductStock>

              <div class="product-item__actions mt-auto">
                <div class="btn-group btn-group-sm ">
                  <button
                    class="btn btn-secondary btn-icon-only btn--add-to-cart"
                    aria-label="Add to cart"
                    v-if="!product.variants && isLoggedIn"
                    @click="addProductToCart"
                  >
                    <i class="icon icon-default icon-shopping_cart"></i>
                    <i
                      class="icon spinner-border d-none mr-1"
                      role="status"
                    ></i>
                  </button>

                  <button
                    class="btn btn-secondary btn-icon-only"
                    aria-label="Add to quote"
                    @click="addProductToQuote"
                  >
                    <i class="icon icon-description"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import ProductPrice from "./ProductPrice.vue";
import ProductStock from "./ProductStock.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ProductPrice,
    ProductStock
  },
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return { productQuantity: 1 };
  },

  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  methods: {
    ...mapActions(["addProducts", "storeProductsToQuote"]),
    addProductToCart(product) {
      let addBtn = document.querySelector(".btn--add-to-cart");
      this.addLoadingIcon(addBtn);
      let productToAdd = this.product.sku;
      this.addProducts({
        sku: productToAdd,
        productQuantity: parseFloat(this.productQuantity)
      }).then(() => {
        this.$emit("update:addedProduct", {
          product: this.product,
          cartType: "cart"
        });
        this.removeLoadingIcon(addBtn);
      });
    },
    addProductToQuote(product) {
      let productToAdd = this.product.sku;
      this.storeProductsToQuote({
        sku: productToAdd,
        productQuantity: parseFloat(this.productQuantity)
      })
        .then(() => {
          this.$emit("update:addedProduct", {
            product: this.product,
            cartType: "quote"
          });
        })
        .catch(() => {
          this.errors = true;
          this.errorMsg = this.$t("order.errors.something-wrong");
        });
    },
    addLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = true;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.add("d-block");
          if (defaultIcon) {
            defaultIcon.classList.remove("d-block");
            defaultIcon.classList.add("d-none");
          }
        }
      }
    },
    removeLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = false;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.remove("d-block");
          loadingIcon.classList.add("d-none");
          if (defaultIcon) {
            defaultIcon.classList.add("d-block");
            defaultIcon.classList.remove("d-none");
          }
        }
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
