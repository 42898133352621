<template>
  <ModalCart
    :product="product"
    :cartType="cartType"
    v-if="product && !isFetchingProduct"
  >
  </ModalCart>
  <section
    class="section shop single single--product"
    v-if="product && !isFetchingProduct"
  >
    <section class="section product-item">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <CarouselProduct v-if="product.media_gallery" />
            <img
              v-if="!product.media_gallery.length"
              :src="product.image.url"
              width="450"
              height="450"
            />
          </div>

          <div class="col-lg-5 offset-lg-1">
            <div class="product-item__content pt-6">
              <div class="product-item__title">
                <h1 class="h4" v-html="product.name"></h1>
              </div>

              <div class="product-item__sku">
                <span> SKU: {{ product.sku }} </span>
              </div>

              <div
                class="product-item__data"
                v-if="
                  product.customer_price.regular_price.value !=
                    product.customer_price.final_price.value
                "
              >
                <table class="table text-sm">
                  <tbody>
                    <tr>
                      <td class="text-xs">
                        <strong v-t="'miscellaneous.labels.catalog-price'"
                          >Catalog price</strong
                        >
                      </td>
                      <td class="text-xs">
                        <strong v-t="'miscellaneous.labels.discount-rate'"
                          >Discount rate</strong
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ProductPrice
                          v-if="isLoggedIn"
                          :price="product.customer_price.regular_price"
                          type="none"
                        ></ProductPrice>
                      </td>
                      <td>
                        {{ product.customer_price.discount.percent_off }}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ProductPrice
                v-if="isLoggedIn && product.customer_price != null"
                :price="product.customer_price.final_price"
                type="xl"
              ></ProductPrice>

              <ProductStock
                :stock="
                  product.customer_stock && product.customer_stock != null
                "
                v-if="
                  product.customer_stock != null &&
                    product.customer_stock != -1 &&
                    isLoggedIn
                "
              ></ProductStock>

              <div class="product-item__actions">
                <div class="row row-tiny-gutter">
                  <div class="col-9 col-xxl-10" v-if="product.variants">
                    <div class="form-group mb-2">
                      <label
                        for="number"
                        v-t="'miscellaneous.labels.choose-variant'"
                        >Select</label
                      >
                      <div class="custom-control custom-selectbox">
                        <select
                          class="form-control"
                          name="product_variant"
                          v-model="productVariant"
                          @change="variantChange"
                        >
                          <option
                            value="Select a variant"
                            v-t="'miscellaneous.labels.choose-variant'"
                          >
                            Select a variant
                          </option>
                          <option
                            v-for="(variant, i) in product.variants"
                            :key="i"
                            :value="variant.product.sku"
                          >
                            {{ variant.product.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-3 col-xxl-2"
                    :class="product.variants ? 'col-3 col-xxl-2' : 'col-3'"
                  >
                    <div class="form-group mb-2">
                      <label for="number" v-t="'miscellaneous.labels.quantity'"
                        >Quantity</label
                      >
                      <div class="custom-control custom-number quantity w-100">
                        <input
                          type="number"
                          class="form-control quantity--input"
                          min="0"
                          name="quantity"
                          v-model="productQuantity"
                        />
                        <button
                          class="btn btn-quantity--plus"
                          @click="quantityIncrement"
                        >
                          <i class="icon icon-keyboard_arrow_up"></i>
                        </button>
                        <button
                          class="btn btn-quantity--minus"
                          @click="quantityDecrement"
                        >
                          <i class="icon icon-keyboard_arrow_down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row row-tiny-gutter">
                  <div class="col-6" v-if="isLoggedIn">
                    <button
                      type="button"
                      class="btn btn-secondary btn-stretch btn--add-to-cart"
                      aria-label="Add to cart"
                      data-target="#myModal"
                      @click="addProductToCart"
                    >
                      <span v-t="'miscellaneous.buttons.add-to-cart'">
                        Add to cart
                      </span>
                      <i
                        class="icon icon-default icon-shopping_cart d-block"
                      ></i>
                      <i
                        class="icon spinner-border d-none mr-1"
                        role="status"
                      ></i>
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-secondary btn-stretch btn--add-to-quote"
                      aria-label="Add to quote"
                      @click="addProductToQuote"
                    >
                      <span v-t="'miscellaneous.buttons.add-to-quote'">
                        Add to quote
                      </span>
                      <i class="icon icon-default icon-description d-block"></i>
                      <i class="icon spinner-border d-none mr-1" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </i>
                    </button>
                  </div>
                </div>
                <div class="row row-tiny-gutter mt-2" v-if="errors">
                  <div class="col-12">
                    <div class="box box--error bg-light py-2 px-3">
                      <span class="text-xs">{{ errorMsg }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="product-item__desc"
                v-if="product.short_description.html.length > 0"
                v-html="product.short_description.html"
              ></div>
              <div class="product-item__anchors btn-group">
                <button
                  class="btn btn-sm btn-outline-primary"
                  v-if="product.description.html.length > 0"
                  v-t="'miscellaneous.titles.description'"
                  @click="goto('description')"
                >
                  Description</button
                ><button
                  class="btn btn-sm btn-outline-primary"
                  v-if="product.attributes.length > 0"
                  v-t="'miscellaneous.titles.specifications'"
                  @click="goto('specifications')"
                >
                  Specifications
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="section text-blocks mb-0"
      id="description"
      v-if="product.description.html.length > 0"
      ref="description"
    >
      <div class="section__header">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <h2
                class="section__title h3 text-center"
                v-t="'miscellaneous.titles.description'"
              >
                Description
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="section__body">
        <div class="text-block">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <div v-html="fixHtmlContent(product.description.html)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <hr class="my-7" v-if="product.description.html.length > 0" />

    <section
      class="section text-blocks mb-0"
      id="specifications"
      v-if="product.attributes.length > 0"
      ref="specifications"
    >
      <div class="section__header">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <h2
                class="section__title h3 text-center"
                v-t="'miscellaneous.titles.specifications'"
              >
                Specifications
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="section__body">
        <div class="text-block">
          <div class="container">
            <div class="row">
              <div class="col-xl-8 offset-xl-2 col-xxl-6 offset-xxl-3">
                <table
                  class="table table-striped text-sm product-item__table w-100"
                >
                  <template
                    v-for="(attribute, i) in product.attributes"
                    :key="i"
                  >
                    <tr
                      :class="[
                        attribute.unit != null && attribute.unit != unit_type
                          ? 'd-none'
                          : ''
                      ]"
                    >
                      <td class="pr-5">{{ attribute.name }}</td>
                      <td>
                        {{ attribute.value }}
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr
      class="mt-7"
      v-if="product.attributes.length > 0 && supportItems.data.length > 0"
      :class="supportItems.data.length > 0 ? 'mb-0' : ''"
    />
  </section>

  <section v-else-if="isFetchingProduct">
    <div class="container d-none d-lg-block">
      <ContentLoader
        viewBox="0 0 1170 690"
        height="100%"
        width="100%"
        style="width: 100%"
      >
        <rect x="0" y="0" width="570" height="570"></rect
        ><rect x="145" y="592" width="88" height="88"></rect
        ><rect x="244" y="592" width="88" height="88"></rect
        ><rect x="340" y="592" width="88" height="88"></rect
        ><rect x="700" y="40" width="420" height="33"></rect
        ><rect x="700" y="86" width="315" height="33"></rect
        ><rect x="700" y="132" width="233" height="20"></rect
        ><rect x="700" y="186" width="195" height="13"></rect
        ><rect x="700" y="211" width="195" height="13"></rect
        ><rect x="700" y="251" width="130" height="38"></rect
        ><rect x="845" y="251" width="130" height="38"></rect>
      </ContentLoader>
    </div>

    <div class="container d-block d-lg-none">
      <ContentLoader
        viewBox="0 0 1170 2170"
        height="100%"
        width="100%"
        style="width: 100%"
      >
        <rect x="0" y="0" width="1170" height="1170"></rect>

        <rect x="323" y="1192" width="160" height="160"></rect>
        <rect x="505" y="1192" width="160" height="160"></rect>
        <rect x="687" y="1192" width="160" height="160"></rect>

        <rect x="0" y="1412" width="840" height="66"></rect>
        <rect x="0" y="1500" width="630" height="40"></rect>
        <rect x="0" y="1560" width="466" height="40"></rect>

        <rect x="0" y="1658" width="390" height="26"></rect>
        <rect x="0" y="1700" width="390" height="26"></rect>

        <rect x="0" y="1800" width="260" height="56"></rect>
        <rect x="280" y="1800" width="260" height="56"></rect>
      </ContentLoader>
    </div>
  </section>
  <Support
    v-if="
      supportItems && supportItems.data.length > 0 && !isFetchingSupportItems
    "
    :supportItems="supportItems.data"
  ></Support>

  <section v-else-if="isFetchingSupportItems" class="section">
    <div class="container d-block d-md-none">
      <ContentLoader viewBox="0 0 1580 1200" style="width: 100%;">
        <rect x="0" y="0" width="420" height="34px" />

        <rect x="0" y="50" width="507" height="336" />
        <rect x="538" y="201" width="800" height="34px" />

        <rect x="0" y="407" width="507" height="336" />
        <rect x="538" y="558" width="800" height="34px" />

        <rect x="0" y="765" width="507" height="336" />
        <rect x="538" y="916" width="800" height="34px" />
      </ContentLoader>
    </div>

    <div class="container d-none d-md-block">
      <ContentLoader viewBox="0 0 1580 480" style="width: 100%;">
        <rect x="0" y="0" width="420" height="33px" />

        <rect x="0" y="50" width="507" height="336" />
        <rect x="538" y="50" width="507" height="336" />
        <rect x="1073" y="50" width="507" height="336" />
      </ContentLoader>
    </div>
  </section>

  <CustomProducts
    :related_products="product.related_products"
    fetchBy="related"
    v-if="
      product && product.related_products && product.related_products.length > 0
    "
  ></CustomProducts>
</template>

<script>
import axios from "axios";
import { ContentLoader } from "vue-content-loader";
import ModalCart from "../../components/Products/ModalCart.vue";
import CarouselProduct from "../../components/Products/CarouselProduct.vue";
import CustomProducts from "../../components/Products/SectionCustom.vue";
import Support from "../../components/Support/Section.vue";
import ProductPrice from "../../components/Products/ProductPrice.vue";
import ProductStock from "../../components/Products/ProductStock.vue";
import { mapGetters, mapActions } from "vuex";
import Modal from "bootstrap.native/src/components/modal-native";
// import ProductListview3 from "../../components/Products/ProductListview3.vue";

export default {
  inheritAttrs: false,

  components: {
    CarouselProduct,
    ProductPrice,
    ProductStock,
    CustomProducts,
    // ProductListview3,
    ModalCart,
    Support,
    ContentLoader
  },
  data() {
    return {
      unit_type: localStorage.getItem("unit_type")
        ? localStorage.getItem("unit_type")
        : "metric",
      active: false,
      show: false,
      cartType: "",
      productSkus: [],
      productQuantity: 1,
      productVariant: "Select a variant",
      productIsValid: true,
      errors: false,
      isFetchingSupportItems: false,
      supportItems: {
        data: {}
      }
    };
  },
  mounted: function() {
    let locale = "en";
    if (this.$i18n.locale != undefined) {
      locale = this.$i18n.locale;
    }

    let that = this;
    this.fetchSingleProduct({ url_key: this.$route.params.productSlug }).then(
      function() {
        if (that.product) {
          document.getElementById("breadcrumb-current").innerHTML =
            that.product.name;

          if (that.product.sku) {
            that.isFetchingSupportItems = true;
            axios
              .get(
                `${process.env.VUE_APP_CMS_ENDPOINT_URL}/wp-json/vt/v1/assets/` +
                  that.product.sku +
                  "?lang=" +
                  locale
              )
              .then(function(response) {
                (that.supportItems = response),
                  (that.isFetchingSupportItems = false);
              })
              .catch(error => console.log(error));
          }

          // Title
          if (that.product.meta_title) {
            document.title = that.product.meta_title + " - VETUS";
            document
              .querySelector('meta[property="og:title"]')
              .setAttribute("content", that.product.meta_title);
          } else {
            document.title = that.product.name + " - VETUS";
            document
              .querySelector('meta[property="og:title"]')
              .setAttribute("content", that.product.name);
          }

          // Meta description
          if (that.product.meta_description) {
            document
              .querySelector('meta[property="og:description"]')
              .setAttribute("content", that.product.meta_description);

            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", that.product.meta_description);
          } else {
            let shortDescription = that.product.short_description.html
              .replace(/(<([^>]+)>)/gi, "") // Remove HTML tags
              .substring(0, 255);
            document
              .querySelector('meta[property="og:description"]')
              .setAttribute("content", shortDescription);

            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", shortDescription);
          }
        } else {
          // 404 when no product found
          that.$router.replace({
            name: "NotFound",
            params: { pathMatch: that.$route.fullPath.substring(1) }
          });
        }
      }
    );
  },
  computed: {
    ...mapGetters([
      "productStatus",
      "isLoggedIn",
      "singleProduct",
      "isFetchingProduct"
    ]),
    product: function() {
      if (this.singleProduct[this.$route.params.productSlug]) {
        return this.singleProduct[this.$route.params.productSlug];
      } else {
        return null;
      }
    },
    product_sku: function() {
      if (this.$store.getters.singleProduct.name != undefined) {
        // let productSkus = [];
        return Object.values(
          this.$store.getters.singleProduct.related_products.map(function(
            related_product
          ) {
            return related_product.sku;
          })
        );
      }
      return [];
    }
  },
  methods: {
    ...mapActions([
      "fetchSingleProduct",
      "addProducts",
      "storeProductsToQuote"
    ]),
    initProductModal() {
      const productModal = document.getElementById("product-modal");
      const productModalInit = new Modal(productModal);
      productModalInit.show();
    },
    quantityIncrement() {
      this.productQuantity++;
    },
    quantityDecrement() {
      if (this.productQuantity === 1) {
        return;
      } else {
        this.productQuantity--;
      }
    },
    addProductToCart() {
      let addBtn = document.querySelector(".btn--add-to-cart");
      this.addLoadingIcon(addBtn);
      let productToAdd = this.product.sku;
      // let productIsValid = this.productIsValid;
      if (this.product.variants) {
        productToAdd = this.productVariant;
      }

      if (this.product.variants && this.productVariant == "Select a variant") {
        this.errors = true;
        this.errorMsg = this.$t("products.errors.select-variant");
      } else {
        this.errors = false;
      }

      if (this.errors == false) {
        this.addProducts({
          sku: productToAdd,
          productQuantity: parseFloat(this.productQuantity)
        })
          .then(() => {
            this.cartType = "cart";
            this.removeLoadingIcon(addBtn);
            this.initProductModal();
          })
          .catch(() => {
            this.errors = true;
            this.errorMsg = this.$t("order.errors.something-wrong");
            this.removeLoadingIcon(addBtn);
          });
      }
    },
    addProductToQuote() {
      let productToAdd = this.product.sku;
      let addBtn = document.querySelector(".btn--add-to-quote");
      this.addLoadingIcon(addBtn);
      if (this.product.variants) {
        productToAdd = this.productVariant;
      }

      if (this.product.variants && this.productVariant == "Select a variant") {
        this.errors = true;
        this.errorMsg = this.$t("products.errors.select-variant");
      } else {
        this.errors = false;
      }

      if (this.errors == false) {
        this.storeProductsToQuote({
          sku: productToAdd,
          productQuantity: parseFloat(this.productQuantity)
        })
          .then(() => {
            this.cartType = "quote";
            setTimeout(() => {
              this.initProductModal();
              this.removeLoadingIcon(addBtn);
            }, 2000);
          })

          .catch(() => {
            this.errors = true;
            this.errorMsg = this.$t("order.errors.something-wrong");
            this.removeLoadingIcon(addBtn);
          });
      }
    },
    addLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = true;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.add("d-block");
          if (defaultIcon) {
            defaultIcon.classList.remove("d-block");
            defaultIcon.classList.add("d-none");
          }
        }
      }
    },
    removeLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = false;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.remove("d-block");
          loadingIcon.classList.add("d-none");
          if (defaultIcon) {
            defaultIcon.classList.add("d-block");
            defaultIcon.classList.remove("d-none");
          }
        }
      }
    },
    variantChange() {
      // console.log(this.productVariant);
      if (this.product.variants && this.productVariant == "Select a variant") {
        this.errors = false;
      } else {
        this.errors = true;
      }
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    fixHtmlContent(html) {
      return html
        .replaceAll("<li><li>", "<li>")
        .replaceAll("</li></li>", "</li>")
        .replaceAll("<b><b>", "<b>")
        .replaceAll("</b></b>", "</b>")
        .replaceAll("<p><p>", "<p>")
        .replaceAll("</p></p>", "</p>")
        .replaceAll("<br/><br/>", "<br/>");
    },
  },
};
</script>

<style lang="css" scoped></style>
