<template>
  <ModalCart :product="modalProduct" :cartType="cartType"> </ModalCart>
  <section class="section cards cards--cat-prod mb-9">
    <div
      class="section__meta search-results__meta d-flex flex-column flex-lg-row justify-content-between align-items-center text-sm"
    >
      <div class="order-2 order-lg-1 mt-3 mt-xl-0 mr-auto">
        <span v-if="products[categoryId]">
          {{ products[categoryId].total_count }} results
        </span>
        <span v-else>
          <ContentLoader viewBox="0 0 80 24" height="25" width="80">
            <rect x="0" y="0" rx="0" ry="0" width="80" height="24" />
          </ContentLoader>
        </span>
      </div>
      <div
        class="section__meta-dropdowns d-flex align-items-center justify-content-between flex-shrink-0 order-1 order-lg-2 flex-wrap"
      >
        <!-- @TODO: [VETUS-288] Add filter button on mobile when layered-navigation is complete -->
        <div class="d-none">
          <button
            class="btn btn-sm btn-secondary d-inline-flex d-lg-none"
            data-toggle="modal"
            data-target="#filter-modal"
          >
            <span v-t="'miscellaneous.buttons.filter'"> Filter </span>
            <i class="icon icon-filter_alt"></i>
          </button>
        </div>

        <label
          for="sort-by"
          class="mb-0 ml-auto mr-3"
          v-t="'miscellaneous.labels.sort-by'"
          >Sort by:</label
        >
        <div class="custom-control custom-selectbox">
          <select
            class="form-control form-control-sm"
            name="sort"
            v-model="sortOption"
            @change="sortChanged()"
          >
            <option
              value="position:ASC"
              data-sort="position"
              v-t="'miscellaneous.labels.relevance'"
            >
              Relevance
            </option>
            <!-- <option value="price:DESC" data-sort="-price">Highest price</option> -->
            <!-- <option value="price:ASC" data-sort="price">Lowest price</option> -->
            <option
              value="name:ASC"
              data-sort="name"
              v-t="'miscellaneous.labels.name-a-z'"
            >
              Name A-Z
            </option>
            <option
              value="name:DESC"
              data-sort="-name"
              v-t="'miscellaneous.labels.name-z-a'"
            >
              Name Z-A
            </option>
          </select>
        </div>

        <button
          class="btn btn-sm btn-icon-only ml-2 d-none d-lg-inline-flex"
          aria-label="Change to grid view"
          @click="viewChanged('grid')"
          v-bind:class="
            layout == 'grid'
              ? 'btn-secondary is-active'
              : 'btn-secondary is-inactive'
          "
        >
          <i class="icon icon-view_module"></i>
        </button>

        <button
          class="btn btn-sm btn-icon-only ml-2 d-none d-lg-inline-flex"
          aria-label="Change to list view"
          @click="viewChanged('list')"
          v-bind:class="
            layout == 'list'
              ? 'btn-secondary is-active'
              : 'btn-secondary is-inactive'
          "
        >
          <i class="icon icon-view_list"></i>
        </button>
      </div>
    </div>

    <hr class="my-3" />
    <div
      class="section__meta mb-3 actions"
      v-if="products[categoryId] && filters"
    >
      <div class="btn-group btn-group-sm d-flex flex-wrap">
        <template
          v-for="(value, attribute_code) in filters"
          :key="'filter-' + attribute_code + '-' + value"
        >
          <button
            class="btn btn-sm btn-outline-primary"
            v-if="!['s', 'p'].includes(attribute_code)"
            @click="removeFilter(attribute_code, value)"
          >
            <span>{{ filterName(attribute_code, value) }}</span>
            <i class="icon icon-close text-sm"></i>
          </button>
        </template>
      </div>
    </div>

    <div
      class="section__products"
      v-if="
        (typeof isFetchingProducts[categoryId] === 'undefined' ||
          isFetchingProducts[categoryId]) &&
        !products[categoryId]
      "
    >
      <ContentLoader
        viewBox="0 0 1180 1130"
        style="width: 100%"
        class="d-none d-lg-block"
      >
        <rect x="0" y="0" width="372" height="372" />
        <rect x="0" y="380" width="200" height="18" />
        <rect x="0" y="404" width="120" height="18" />

        <rect x="402" y="0" width="372" height="372" />
        <rect x="402" y="380" width="200" height="18" />
        <rect x="402" y="404" width="120" height="18" />

        <rect x="804" y="0" width="372" height="372" />
        <rect x="804" y="380" width="200" height="18" />
        <rect x="804" y="404" width="120" height="18" />

        <rect x="0" y="480" width="372" height="372" />
        <rect x="0" y="860" width="200" height="18" />
        <rect x="0" y="884" width="120" height="18" />

        <rect x="402" y="480" width="372" height="372" />
        <rect x="402" y="860" width="200" height="18" />
        <rect x="402" y="884" width="120" height="18" />

        <rect x="804" y="480" width="372" height="372" />
        <rect x="804" y="860" width="200" height="18" />
        <rect x="804" y="884" width="120" height="18" />
      </ContentLoader>
    </div>
    <div class="section__products" v-if="products[categoryId]">
      <div class="row row--col-margin-medium" v-if="layout === 'grid'">
        <div
          class="col-12 col-sm-6 col-md-4"
          v-for="(product, i) in products[categoryId].items"
          :key="i"
        >
          <Product :product="product" @update:addedProduct="initProductModal">
          </Product>
        </div>
      </div>
    </div>

    <ul
      class="list-view list-inline"
      v-if="layout === 'list' && products[categoryId]"
    >
      <template v-for="(product, i) in products[categoryId].items" :key="i">
        <ProductListview
          :product="product"
          @update:addedProduct="initProductModal"
        >
        </ProductListview>
      </template>
    </ul>
  </section>

  <Pagination
    @update:page="pageNumber"
    :pagination="pagination"
    :totalCount="products[categoryId].total_count"
    v-if="
      products[categoryId] &&
      products[categoryId].total_count > pageSize &&
      !isFetchingProducts[categoryId]
    "
  ></Pagination>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import Product from "./../../components/Products/Product.vue";
import ProductListview from "./../../components/Products/ProductListview.vue";
import Pagination from "./../../components/Misc/Pagination.vue";
import ModalCart from "../../components/Products/ModalCart.vue";
import Modal from "bootstrap.native/src/components/modal-native";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Product,
    ProductListview,
    Pagination,
    ModalCart,
    ContentLoader,
  },
  props: {
    categorySlug: {
      type: String,
    },
    categoryId: {
      type: Number,
    },
    productCount: {
      type: Number,
    },
  },
  data() {
    return {
      cartType: "",
      modal: false,
      modalProduct: {},
      layout: "grid",
      sortOption: "position:ASC",
      sortOptionParam: "position",
      totalCount: 0,
      filters: {},
      pageSize: 24,
      pagination: {
        pageSize: 15,
        currentPage: 1,
        totalCount: 0,
        numberOfPages: 0,
      },
    };
  },
  created: function () {
    for (let [key, value] of Object.entries(this.$route.query)) {
      this.filters[key] = value;
    }
    if (!localStorage.getItem("layout")) {
      this.layout = "grid";
      localStorage.setItem("layout", "grid");
    }
    this.layout = localStorage.getItem("layout");

    if (this.$route.query.s) {
      this.sortOption = this.$route.query.s;
    }

    if (this.$route.query.p) {
      this.pagination.currentPage = Number(this.$route.query.p);
    }

    this.pagination.numberOfPages = Math.ceil(this.productCount / 15);

    this.pagination.totalCount = this.productCount;
    this.fetchProducts({
      categoryId: this.categoryId,
      filters: this.filters,
    });
  },
  computed: {
    ...mapGetters(["categories", "products", "isFetchingProducts"]),
  },
  methods: {
    ...mapActions(["fetchProducts"]),
    initProductModal(value) {
      this.cartType = value.cartType;
      this.modalProduct = value.product;
      this.modal = true;
      const productModal = document.getElementById("product-modal");
      const productModalInit = new Modal(productModal);
      productModalInit.show();
    },
    sortChanged: function () {
      if (this.filters.p) delete this.filters.p;
      this.filters.s = this.sortOption;
      this.routeReplace();
    },
    pageNumber: function (value) {
      this.pagination.currentPage = value;
      this.productListUpdate();
    },
    productListUpdate: function () {
      this.filters.p = this.pagination.currentPage;
      this.routeReplace();

      this.fetchProducts({
        categoryId: this.categoryId,
        filters: this.filters,
      });
    },
    viewChanged(view) {
      this.layout = view;
      localStorage.setItem("layout", view);
    },
    filterName(filter, key) {
      if (
        this.products[this.categoryId].aggregations[filter] &&
        this.products[this.categoryId].aggregations[filter].options[key]
      ) {
        return (
          this.products[this.categoryId].aggregations[filter].label +
          " - " +
          this.products[this.categoryId].aggregations[filter].options[key].label
        );
      }
    },
    removeFilter(attribute_code, value) {
      if (this.filters.p) delete this.filters.p;
      let array = this.filters[attribute_code].split(",");
      if (array.includes(value)) {
        let index = array.indexOf(value);
        index > -1 ? array.splice(index, 1) : "";
      } else {
        array.push(value);
      }
      this.filters[attribute_code] = array.join();
      if (this.filters[attribute_code] === "")
        delete this.filters[attribute_code];
      this.routeReplace();
    },
    routeReplace() {
      this.$router.replace({
        name: "SubCategory",
        query: this.filters,
      });
    },
  },
};
</script>

<style lang="css" scoped>
.is-inactive {
  opacity: 0.6;
}
</style>
