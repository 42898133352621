<template>
  <!-- Used in modal -->
  <li class="list-item product-item--stripped">
    <div class="row">
      <div class="col-3 col-sm-2">
        <div class="list-item__visuals">
          <div class="list-item__media media ratio ratio--square">
            <router-link
              :to="{
                name: 'ProductSingle',
                params: {
                  locale: this.$i18n.locale,
                  productSlug: product.url_key,
                },
              }"
              class="media__wrapper"
              tabindex="-1"
              :title="product.name"
            >
              <img :src="product.image.url" :alt="product.name" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-9 col-sm-10">
        <div class="list-item__body">
          <div class="row d-flex">
            <div class="col-md-8 col-lg-7 col-xl-8">
              <div class="list-item__content product-item__content">
                <div class="list-item__title product-item__title">
                  <router-link
                    :to="{
                      name: 'ProductSingle',
                      params: {
                        locale: this.$i18n.locale,
                        productSlug: product.url_key,
                      },
                    }"
                    class="media__wrapper"
                    tabindex="-1"
                    :title="product.name"
                  >
                    <span v-html="product.name"></span>
                  </router-link>
                </div>
                <div class="list-item__label product-item__sku">
                  <span> SKU: {{ product.sku }} </span>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-5 col-xl-4">
              <div class="list-item__meta d-flex flex-column">
                <div class="product-item__price price">
                  <ProductPrice
                    v-if="isLoggedIn && product.customer_price != null"
                    :price="product.customer_price.final_price"
                  ></ProductPrice>
                </div>
                <!-- Disable buttons for now -->
                <!-- <div class="product-item__actions mt-2 mb-0">
                  <button
                    class="btn btn-sm btn-secondary btn-icon-only"
                    aria-label="Add to cart"
                    data-toggle="modal"
                    @click="addProductToCart"
                  >
                    <i class="icon icon-shopping_cart"></i>
                  </button>

                  <button
                    class="btn btn-sm btn-secondary btn-icon-only"
                    aria-label="Add to quote"
                    data-toggle="modal"
                    data-target="#add-to-quote-modal"
                  >
                    <i class="icon icon-description"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import { mapActions } from "vuex"; //
import ProductPrice from "./ProductPrice.vue";

export default {
  components: {
    ProductPrice
  },
  props: {
    product: {
      type: Object
    },
    quantity: {
      type: Number
    },
    cartItemId: {
      type: Number
    }
  },
  data() {
    return {
      productQuantity: 1
    };
  },

  created() {},

  methods: {
    ...mapActions(["addProducts"])
    // addProductToCart() {
    //   let productToAdd = this.product.sku;
    //   // console.log(productToAdd);
    //   this.addProducts({
    //     sku: productToAdd,
    //     productQuantity: parseFloat(this.productQuantity)
    //   });
    // }
  }
};
</script>
