<template>
  <section class="section cards cards--product products">
    <div class="section__header mb-4 cards__header">
      <div class="container">
        <div class="d-flex">
          <h3
            class="section__title cards__title"
            v-t="'miscellaneous.titles.related-products'"
          >
            Related Products
          </h3>
        </div>
      </div>
    </div>

    <div class="section__body cards__body">
      <div class="container">
        <div class="row row--col-margin-medium">
          <div
            class="col-12 col-sm-6 col-md-3"
            v-for="(product, i) in related_products"
            :key="i"
          >
            <Product :product="product"> </Product>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "./../../components/Products/Product.vue";

export default {
  components: {
    Product
  },
  props: {
    related_products: { type: Array },
    fetchBy: { type: String }
  },
  data() {
    return {};
  }
};
</script>

<style lang="css" scoped></style>
