<template>
  <div class="product-item__stock stock">
    <span
      class="stock__circle"
      :class="stock > 0 ? 'bg-success' : 'bg-warning'"
    ></span>
    <span
      class="stock__text text-sm"
      v-if="stock > 0"
      v-t="'miscellaneous.labels.in-stock'"
    >
    </span>
    <span
      class="stock__text text-sm"
      v-else
      v-t="'miscellaneous.labels.out-of-stock'"
    >
    </span>
  </div>
</template>

<script>
export default {
  props: {
    stock: {
      type: Number
    }
  },
  data: function() {
    return {};
  },
  created() {
    // For now, there's no "almost out of stock" option
    // For now, all "out of stock" items are not being output by Magento
  }
};
</script>

<style lang="css" scoped></style>
