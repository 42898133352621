<template>
  <div class="card card--cat-product">
    <div class="card__header">
      <div class="card__media media ratio text-xl bg-transparent">
        <div class="media__wrapper">
          <img :src="category.image" :alt="category.name" />
        </div>
      </div>
    </div>

    <div class="card__body">
      <div class="card__title">

        <router-link
        v-if="$route.params.categorySlug"
        :to="{ name: 'SubCategory', params: { subCategorySlug : category.url_key} }"
          class="stretched-link text-sm"
          :title="category.name"
          :category="category"
        >
          <strong>{{ category.name }}</strong>
        </router-link>      

        <router-link
        v-else
        :to="{ name: 'Category', params: { categorySlug : category.url_key} }"
          class="stretched-link text-sm"
          :title="category.name"
          :category="category"
        >
          <strong>{{ category.name }}</strong>
        </router-link>        

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Object
    },
    categoryName: {
      type: String
    }
  },
  name: "category",
  created() {
    // console.log(this.category);
  },
  methods() {}
};
</script>

<style lang="css" scoped></style>
