<template>
  <div class="card card--default">
    <div
      class="card__header bg-light
"
    >
      <div class="card__media media ratio">
        <div class="media__wrapper">
          <img
            :src="supportItem.featured_image"
            class="img-fit"
            :alt="supportItem.title"
            v-if="supportItem.featured_image"
          />
        </div>
      </div>
    </div>

    <div class="card__body">
      <!-- <div class="card__label">
        <span>

        </span>
      </div> -->

      <div class="card__title">
        <a
          :href="supportItem.media"
          class="stretched-link h5"
          :title="supportItem.title"
          target="_blank"
        >
          {{ supportItem.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    supportItem: {
      type: Object
    }
  }
};
</script>

<style lang="css" scoped></style>
