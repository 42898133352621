<template> &nbsp;</template>

<script>
// import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {}
};
</script>

<style lang="css" scoped></style>
