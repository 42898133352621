<template>
  <section
    class="section request-a-quote steps text-sm mb-0"
    v-if="quoteProducts && quoteProducts.total_count > 0"
  >
    <!--    is-done is-active -->
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <router-link
            :to="{ name: 'Quote' }"
            class="step"
            :class="classStepOne()"
            @click="checkClick($event)"
          >
            <span class="step__number">
              <span v-if="currentRouteName == 'Quote'">
                1
              </span>
              <i class="icon icon-done" v-else></i>
            </span>
            <span class="step__title" v-t="'shop.quote.check-the-products.tab'">
              Check the products
            </span>
          </router-link>

          <router-link
            :to="{ name: 'Choose a dealer' }"
            class="step"
            :event="''"
            :class="classStepTwo()"
            @click="checkClick($event)"
            v-if="!isLoggedIn"
          >
            <span class="step__number">
              <span
                v-if="
                  currentRouteName == 'Choose a dealer' ||
                    currentRouteName == 'Quote'
                "
              >
                2
              </span>
              <i class="icon icon-done" v-else></i>
            </span>
            <span class="step__title" v-t="'shop.quote.choose-a-dealer.tab'">
              Choose a dealer
            </span>
          </router-link>
          <router-link
            :to="{ name: 'Contact info' }"
            class="step"
            :class="(classStepThree(), { disabled: !quoteDealerSet() })"
          >
            <span class="step__number">
              <span v-if="isLoggedIn">2</span>
              <span v-else>3</span>
            </span>
            <span class="step__title" v-t="'shop.quote.contact-info.tab'">
              Contact info
            </span>
          </router-link>

          <router-link
            :to="{ name: 'Choose a dealer' }"
            class="btn btn-secondary ml-auto d-none d-lg-inline-flex"
            v-if="currentRouteName == 'Quote' && !isLoggedIn"
          >
            <span v-t="'shop.quote.proceed-dealer'"
              >Continue to choose a dealer</span
            >
            <i class="icon icon-keyboard_arrow_right"></i>
          </router-link>
          <div v-else>
            <button class="btn disabled"><span>&nbsp;</span></button>
          </div>
        </div>
      </div>
      <hr class="mt-3 mb-3 " />
    </div>
  </section>

  <router-view
    v-if="quoteProducts && quoteProducts.total_count > 0"
  ></router-view>
 <!-- v-else-if="isFetchingQuotes" -->
  <section
    class="section products products--empty"
    v-else-if="isFetchingQuotes"
  >

    <div class="container">
      <ContentLoader
        viewBox="0 0 1180 620"
        style="width: 100%;"
      >

        <rect x="0" y="0" width="200" height="50" />
        <rect x="230" y="0" width="200" height="50" />
        <rect x="0" y="75" width="1180" height="200" />
        <rect x="0" y="295" width="1180" height="200" />
      </ContentLoader>
    </div>

  </section>
  <section class="section products products--empty" v-else>
    <div class="container text-center">
      <p v-t="'shop.quote.empty'">
        Your quote is empty. Visit the webshop to add products to your quote.
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ContentLoader
  },
  data() {
    return {};
  },
  created() {
    this.fetchQuoteProducts();
    this.fetchDealers();
  },
  methods: {
    ...mapActions(["fetchQuoteProducts", "fetchDealers"]),
    classStepOne() {
      if (this.currentRouteName == "Quote") {
        return "is-active";
      }
      if (
        this.currentRouteName == "Choose a dealer" ||
        this.currentRouteName == "Contact info"
      ) {
        return "is-done";
      }
    },
    classStepTwo() {
      if (this.currentRouteName == "Choose a dealer") {
        return "is-active";
      }
      if (this.currentRouteName == "Contact info") {
        return "is-done";
      }
    },
    classStepThree() {
      if (this.currentRouteName == "Contact info") {
        return "is-active";
      }
    },
    checkClick(e) {
      e.preventDefault();
    },
    quoteDealerSet() {
      if (this.quoteDealer.length) {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters([
      "quoteProducts",
      "quote",
      "isLoggedIn",
      "vetusdealers",
      "quoteDealer",
      "isFetchingQuotes"
    ]),
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>

<style lang="css" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
