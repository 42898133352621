<template>
  <div class="card card--product product-item">
    <div class="card__header">
      <div class="card__media media ratio ratio--square">
        <router-link
          :to="{
            name: 'ProductSingle',
            params: {
              locale: this.$i18n.locale,
              productSlug: product.url_key,
              product: { product }
            }
          }"
          class="media__wrapper"
          tabindex="-1"
          :title="product.name"
          :product="product.name"
        >
          <img :src="product.image.url" :alt="product.name" />
        </router-link>
      </div>
    </div>

    <div class="card__body product-item__body">
      <div class="card__title product-item__title product-item__title--trunc">
        <router-link
          :to="{
            name: 'ProductSingle',
            params: {
              locale: this.$i18n.locale,
              productSlug: product.url_key,
              product: { product }
            }
          }"
          class="text-subtitle"
          tabindex="-1"
          :title="product.name"
        >
          <span v-html="product.name"></span>
        </router-link>
      </div>

      <div class="card__label product-item__sku">
        <span>
          {{ product.sku }}
        </span>
      </div>

      <div class="card__price product-item__price price" v-if="isLoggedIn">
        <ProductPrice
          v-if="isLoggedIn && product.customer_price != null"
          :price="product.customer_price.final_price"
        ></ProductPrice>
      </div>

      <div class="card__stock product-item__stock stock" v-if="isLoggedIn">
        <ProductStock
          :stock="product.customer_stock"
          v-if="
            product.customer_stock != null &&
              product.customer_stock != -1 &&
              isLoggedIn
          "
        ></ProductStock>
      </div>

      <div class="card__actions actions">
        <div class="btn-group btn-group-sm d-flex flex-wrap">
          <button
            class="btn btn-secondary btn-icon-only btn--add-to-cart"
            aria-label="Add to cart"
            v-if="!product.variants && isLoggedIn"
            @click="addProductToCart"
          >
            <i class="icon icon-default icon-shopping_cart"></i>
            <i class="icon spinner-border d-none" role="status"></i>
          </button>

          <button
            class="btn btn-secondary btn-icon-only btn--add-to-quote"
            aria-label="Add to quote"
            v-if="!product.variants"
            @click="addProductToQuote"
          >
            <i class="icon icon-default icon-description"></i>
            <i class="icon spinner-border d-none" role="status"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductPrice from "./ProductPrice.vue";
import ProductStock from "./ProductStock.vue";

export default {
  props: ["product"],
  components: {
    ProductPrice,
    ProductStock
  },
  data() {
    return { productQuantity: 1 };
  },
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  methods: {
    ...mapActions(["addProducts", "storeProductsToQuote"]),
    addProductToCart(product) {
      let addBtn = document.querySelector(".btn--add-to-cart");
      this.addLoadingIcon(addBtn);
      let productToAdd = this.product.sku;
      this.addProducts({
        sku: productToAdd,
        productQuantity: parseFloat(this.productQuantity)
      }).then(() => {
        this.$emit("update:addedProduct", {
          product: this.product,
          cartType: "cart"
        });
        this.removeLoadingIcon(addBtn);
      });
    },
    addProductToQuote(product) {
      let productToAdd = this.product.sku;
      this.storeProductsToQuote({
        sku: productToAdd,
        productQuantity: parseFloat(this.productQuantity)
      })
        .then(() => {
          this.$emit("update:addedProduct", {
            product: this.product,
            cartType: "quote"
          });
        })
        .catch(() => {
          this.errors = true;
          this.errorMsg = this.$t("order.errors.something-wrong");
        });
    },
    addLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = true;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.add("d-block");
          if (defaultIcon) {
            defaultIcon.classList.remove("d-block");
            defaultIcon.classList.add("d-none");
          }
        }
      }
    },
    removeLoadingIcon(btnName) {
      let btn = btnName;
      if (btn) {
        btn.disabled = false;
        let loadingIcon = btn.querySelector(".spinner-border");
        let defaultIcon = btn.querySelector(".icon-default");
        if (loadingIcon) {
          loadingIcon.classList.remove("d-block");
          loadingIcon.classList.add("d-none");
          if (defaultIcon) {
            defaultIcon.classList.add("d-block");
            defaultIcon.classList.remove("d-none");
          }
        }
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
