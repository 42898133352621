<template>
  <form action="" v-on:submit.prevent @submit="submitRequest($event)">
    <div class="form-fields">
      <div class="form-section">
        <div class="form-header">
          <h3 v-t="'order.billing-address'">Billing address</h3>
        </div>
        <div class="form-group">
          <label for="input-reference" v-t="'order.reference'"
            >Your reference</label
          >
          <input
            type="text"
            class="form-control"
            id="input-reference"
            aria-describedby="Reference"
            placeholder="Your reference"
            v-model="form.reference"
            maxlength="35"
          />
        </div>

        <div class="form-group">
          <div v-if="billing_address.id">
            <span v-if="this.userBillingAddress.company" class="d-block">
              {{ this.userBillingAddress.company }}
            </span>
            <div v-if="this.userBillingAddress.street">
              <template
                v-for="(streetItem, i) in this.userBillingAddress.street"
                :key="i"
              >
                <span class="d-block">{{ streetItem }}</span>
              </template>
            </div>
            <span v-if="this.userBillingAddress.postcode">
              {{ this.userBillingAddress.postcode }}
            </span>
            <span v-if="this.userBillingAddress.city"
              >&nbsp;{{ this.userBillingAddress.city }}</span
            >
            <span v-if="this.userBillingAddress.region.region" class="d-block">
              {{ this.userBillingAddress.region.region }}
            </span>
            <span v-if="this.userBillingAddress.country_code" class="d-block">
              {{ this.userBillingAddress.country_code }}
            </span>
            <span v-if="this.userBillingAddress.telephone" class="d-block">
              {{ this.userBillingAddress.telephone }}
            </span>
            <span v-if="authUser.email" class="d-block">
              {{ authUser.email }}
            </span>
          </div>
          <div v-else>
            <p v-t="'account.dashboard.address-book.no-billing'">
              You have no billing addresses yet.
            </p>
          </div>
        </div>
        <hr class="mt-0" />
      </div>

      <div
        class="form-section"
        :class="shipping_address == 'new-shipping-address' ? 'mb-0' : ''"
      >
        <div class="form-header">
          <h3 v-t="'order.shipping-address'">Shipping address</h3>
        </div>

        <div class="form-group">
          <div class="custom-control custom-selectbox">
            <select
              class="form-control"
              id="select-shipping-address"
              v-model="shipping_address"
              @change="shippingAddressChange"
            >
              <option
                v-for="(address, i) in authUser.addresses"
                :value="address.id"
                :key="i"
              >
                <template v-for="(streetItem, ii) in address.street" :key="ii">
                  {{ streetItem }},
                </template>
                {{ address.postcode }},
                {{ address.city }}
              </option>
              <option
                value="new-shipping-address"
                v-t="'order.new-shipping-address'"
              >
                Add new shipping address
              </option>
            </select>
          </div>
        </div>
      </div>

      <div :class="shipping_address != 'new-shipping-address' ? 'd-none' : ''">
        <div class="form-row" v-if="shipping_address == 'new-shipping-address'">
          <div class="form-group col-6">
            <label for="input-shipping-firstname" v-t="'order.first-name'"
              >First Name *</label
            >
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': v$.form.shipping_address.firstname.$error,
              }"
              id="input-shipping-firstname"
              aria-describedby="input-shipping-firstname"
              placeholder="Your first name"
              v-model="form.shipping_address.firstname"
              :readonly="shipping_address != 'new-shipping-address'"
            />
          </div>
          <div class="form-group col-6">
            <label for="input-shipping-lastname" v-t="'order.last-name'"
              >Last Name *</label
            >
            <input
              type="text"
              class="form-control"
              id="input-shipping-lastname"
              :class="{
                'is-invalid': v$.form.shipping_address.lastname.$error,
              }"
              aria-describedby="input-shipping-lastname"
              placeholder="Your last name"
              v-model="form.shipping_address.lastname"
              :readonly="shipping_address != 'new-shipping-address'"
            />
          </div>
        </div>

        <div class="form-group d-none">
          <label for="input-email" v-t="'order.email'">Email *</label>
          <input
            type="email"
            class="form-control"
            id="input-shipping-email"
            aria-describedby="input-shipping-email"
            placeholder="Enter email"
            :value="authUser.email"
            disabled
          />
          <small class="form-text text-muted" v-t="'order.share-email'"
            >We'll never share your email with anyone else.</small
          >
        </div>

        <div class="form-group">
          <label for="input-shipping-phone-number"
            >Phone number
            <span class="text-muted" v-t="'order.optional'"
              >Optional</span
            ></label
          >
          <input
            type="text"
            class="form-control"
            id="input-shipping-phone-number"
            aria-describedby="input-shipping-phone-number"
            placeholder="Phone number"
            v-model="form.shipping_address.telephone"
            :readonly="shipping_address != 'new-shipping-address'"
          />
        </div>

        <div class="form-group">
          <label for="input-shipping-address-1" v-t="'order.shipping-address'"
            >Shipping address *</label
          >
          <input
            type="text"
            class="form-control"
            :class="{
              'is-invalid': v$.form.shipping_address.street.$error,
            }"
            id="input-shipping-address-1"
            aria-describedby="input-shipping-address-1"
            placeholder="Address"
            v-model="form.shipping_address.street[0]"
            :readonly="shipping_address != 'new-shipping-address'"
          />
          <input
            type="text"
            class="form-control"
            id="input-shipping-address-2"
            aria-describedby="input-shipping-address-2"
            v-model="form.shipping_address.street[1]"
            :readonly="shipping_address != 'new-shipping-address'"
          />
          <input
            type="text"
            class="form-control"
            id="input-shipping-address-3"
            aria-describedby="input-shipping-address-3"
            v-model="form.shipping_address.street[2]"
            :readonly="shipping_address != 'new-shipping-address'"
          />
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="input-shipping-postal-code" v-t="'order.postal-code'"
              >Postal code *</label
            >
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': v$.form.shipping_address.postcode.$error,
              }"
              id="input-shipping-postal-code"
              aria-describedby="input-shipping-postal-code"
              placeholder="Postal code"
              v-model="form.shipping_address.postcode"
              :readonly="shipping_address != 'new-shipping-address'"
            />
          </div>
          <div class="form-group col-6">
            <label for="input-shipping-city">City / town *</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': v$.form.shipping_address.city.$error,
              }"
              id="input-shipping-city"
              aria-describedby="input-shipping-city"
              placeholder="City"
              v-model="form.shipping_address.city"
              :readonly="shipping_address != 'new-shipping-address'"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-6" v-if="countries">
            <label for="input-shipping-country">Country *</label>
            <div class="custom-control custom-selectbox">
              <select
                class="form-control"
                :class="{
                  'is-invalid': v$.form.shipping_address.country_code.$error,
                }"
                id="input-shipping-country"
                v-model="form.shipping_address.country_code"
                @change="countrySelected($event)"
                :disabled="shipping_address != 'new-shipping-address'"
              >
                <!-- <option selected="">Select a country </option> -->
                <template v-for="(country, i) in countries" :key="i">
                  <option :value="country.two_letter_abbreviation">
                    {{ country.full_name_locale }}
                  </option>
                </template>
              </select>
            </div>
          </div>

          <div class="form-group col-6" v-if="regions && regions.length > 0">
            <label for="input-shipping-region">State / province *</label>
            <div class="custom-control custom-selectbox">
              <select
                class="form-control"
                id="input-shipping-region"
                v-model="form.shipping_address.region"
                :disabled="shipping_address != 'new-shipping-address'"
              >
                <!-- <option selected="">Select a region </option> -->
                <template v-for="(region, i) in regions" :key="i">
                  <option :value="region.code">{{ region.name }}</option>
                </template>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="input-shipping-company"
            >Company <span class="text-muted">Optional</span></label
          >
          <input
            type="text"
            class="form-control"
            id="input-shipping-company"
            aria-describedby="input-shipping-company"
            placeholder="Company"
            v-model="form.shipping_address.company"
            :readonly="shipping_address != 'new-shipping-address'"
          />
        </div>

        <!--
                <div class="form-section">
                  <div class="form-header">
                    <h3>Shipping method</h3>
                  </div>

                  <div class="form-group">
                    <div
                      class="custom-control custom-control--styled custom-check"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input custom-control-input--styled"
                        id="checkbox-2"
                        name="checkbox-2"
                      />
                      <label
                        class="custom-control-label custom-control-label--styled"
                        for="checkbox-2"
                      >
                        Check this custom checkbox<br />
                        <span class="text-sm">
                          Sublabel
                        </span>
                      </label>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="box__body bg-light">
                      <div class="box__content">
                        <table class="table table--products w-100">
                          <tr>
                            <td>
                              Shipping & handling
                            </td>
                            <td>
                              <div class="price">
                                <ProductPrice></ProductPrice>
                              </div>
                            </td>
                          </tr>
                        </table>

                        <hr class="mt-0 mb-5" />

                        <div class="text-center">
                          <button class="btn btn-secondary">
                            <span>
                              Estimate shipping &amp; handling costs
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
-->
      </div>
      <div class="form-section" v-if="cart.available_payment_methods">
        <div class="form-header">
          <h3 v-t="'order.payment-method'">Payment method</h3>
        </div>

        <div class="form-group">
          <div class="custom-control custom-selectbox">
            <select
              class="form-control"
              id="select-payment-method"
              v-model="payment_method"
              @change="paymentMethodChange"
            >
              <option
                v-for="(method, i) in cart.available_payment_methods"
                :value="method.code"
                :key="i"
              >
                {{ method.title }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-section">
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-secondary"
            v-t="'order.submit-request'"
            :disabled="!billing_address.id"
          >
            Submit request
          </button>
        </div>
        <div class="form-group" v-if="errors">
          <div class="box box--error bg-light py-2 px-3">
            <span class="text-xs">{{ errorMsg }}</span>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const customRequired = (value) => {
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.length == 0) {
    return false;
  }

  return value[0].length > 0;
};

/* @TODO: Make this work
const requiredIfNewShippingAddress = value => {
  if (value.length == 0) {
    return false;
  }
 return value[0].length > 0;
};
*/

export default {
  data() {
    return {
      errors: false,
      errorMsg: "",
      payment_method: "",
      shipping_method: {},
      billing_address: {},
      shipping_address: "new-shipping-address",
      regions: null,
      form: {
        reference: "",
        billing_address: {
          id: "",
          firstname: "",
          lastname: "",
          company: "",
          street: [],
          city: "",
          region: "",
          postcode: "",
          country_code: "",
          telephone: "",
          save_in_address_book: false,
        },
        shipping_address: {
          id: "",
          firstname: "",
          lastname: "",
          company: "",
          street: [],
          city: "",
          region: "",
          postcode: "",
          country_code: "",
          telephone: "",
          save_in_address_book: false,
        },
        payment_method: {
          code: "",
        },
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    let address = this.shipping_address;
    if (address == "new-shipping-address") {
      return {
        form: {
          shipping_address: {
            firstname: {
              required,
            },
            lastname: {
              required,
            },
            street: {
              required,
              customRequired,
            },
            city: {
              required,
            },
            postcode: {
              required,
            },
            country_code: {
              required,
            },
          },
        },
      };
    } else {
      return {
        form: {
          shipping_address: {
            firstname: {},
            lastname: {},
            street: {},
            city: {},
            postcode: {},
            country_code: {},
          },
        },
      };
    }
  },
  computed: {
    ...mapGetters(["authUser", "cart", "countries"]),
    userBillingAddress() {
      if (this.authUser.addresses.length > 0) {
        let defaultBillingAddress = this.authUser.addresses.find(function (
          address
        ) {
          return address.default_billing;
        });
        return defaultBillingAddress;
      }
      return null;
    },
    userShippingAddress() {
      if (this.authUser.addresses.length > 0) {
        let defaultShippingAddress = this.authUser.addresses.find(function (
          address
        ) {
          return address.default_shipping;
        });
        return defaultShippingAddress;
      }
      return null;
    },
  },
  created() {
    this.fetchCountries();
    let defaultBillingAddress = this.authUser.addresses.find(function (
      address
    ) {
      return address.default_billing;
    });
    let defaultShippingAddress = this.authUser.addresses.find(function (
      address
    ) {
      return address.default_shipping;
    });

    let firstPaymentMethod = "";
    if (this.cart.available_payment_methods) {
      firstPaymentMethod = this.cart.available_payment_methods[0].code;
    }

    // For now, get first shipping method
    if (
      this.cart.shipping_addresses &&
      this.cart.shipping_addresses.length > 0 &&
      this.cart.shipping_addresses[0].available_shipping_methods
    ) {
      let firstShippingMethod =
        this.cart.shipping_addresses[0].available_shipping_methods[0];
      this.shipping_method = firstShippingMethod;
    } else {
      this.shipping_method = {
        carrier_code: "flatrate",
        method_code: "flatrate",
      };
    }

    if (defaultBillingAddress != undefined) {
      this.billing_address.id = defaultBillingAddress.id;
      this.form.billing_address = this.userBillingAddress;
      this.form.billing_address.id = this.userBillingAddress.id;
    }

    if (defaultShippingAddress != undefined) {
      this.shipping_address = defaultShippingAddress.id;
      this.form.shipping_address = this.userShippingAddress;
      this.form.shipping_address_id = this.userShippingAddress.id;
    } else {
      this.shipping_address = "new-shipping-address";
    }

    this.payment_method = firstPaymentMethod;
  },
  methods: {
    ...mapActions([
      "setBillingAddress",
      "setShippingAddress",
      "setPaymentMethod",
      "setShippingMethod",
      "placeOrder",
      "fetchCountries",
    ]),
    focusFirstStatus() {
      let invalids = document.querySelector(".is-invalid");
      if (invalids) {
        let domRect = document
          .querySelector(".is-invalid")
          .getBoundingClientRect();
        if (domRect) {
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      }
    },
    submitRequest() {
      this.form.errors = [];
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        this.setBillingAddress(this.form.billing_address.id)
          .then((response) => {
            // console.log("Response setBillingAddress", response);
            this.setShippingAddress(this.form.shipping_address)
              .then((response) => {
                //console.log("Response setShippingAddress", response);
                // console.log(this.shipping_method);
                this.setShippingMethod(this.shipping_method)
                  .then((response) => {
                    // console.log("Response setShippingMethod", response);
                    // console.log(this.payment_method);
                    this.setPaymentMethod(this.payment_method)
                      .then((response) => {
                        // console.log("Response setPaymentMethod", response);
                        this.placeOrder({
                          reference: this.form.reference,
                        })
                          .then((response) => {
                            // console.log("Response placeOrder", response);
                            if (
                              response.order_number &&
                              response.order_number != "undefined"
                            ) {
                              this.$router.replace({
                                name: "Confirmation",
                                params: { orderNumber: response.order_number },
                              });
                            }
                          })
                          .catch(() => {
                            this.errors = true;
                            this.errorMsg = this.$t(
                              "order.errors.something-wrong"
                            );
                          });
                      })
                      .catch(() => {
                        this.errors = true;
                        this.errorMsg = this.$t(
                          "order.errors.something-wrong-payment"
                        );
                      });
                  })
                  .catch(() => {
                    this.errors = true;
                    this.errorMsg = this.$t(
                      "order.errors.something-wrong-shipping-method"
                    );
                  });
              })
              .catch(() => {
                this.errors = true;
                this.errorMsg = this.$t(
                  "order.errors.something-wrong-shipping"
                );
              });
          })
          .catch(() => {
            this.errors = true;
            this.errorMsg = this.$t("order.errors.something-wrong-billing");
          });
      } else {
        //console.log("Errors", this.v$);
        this.$nextTick(() => this.focusFirstStatus());
      }
    },
    billingAddressChange() {
      let newAddress = this.authUser.addresses.find(
        (a) => a.id == this.billing_address
      );
      this.form.billing_address = newAddress;
    },
    shippingAddressChange(event) {
      let newAddress;
      let customAddress = {
        firstname: "",
        lastname: "",
        company: "",
        street: [],
        city: "",
        region: "",
        postcode: "",
        country_code: "",
        telephone: "",
        save_in_address_book: false,
      };
      if (event.target.value == "new-shipping-address") {
        newAddress = customAddress;
      } else {
        newAddress = this.authUser.addresses.find(
          (a) => a.id == this.shipping_address
        );
      }
      this.form.shipping_address = newAddress;
    },
    countrySelected(event) {
      // this.form.address.region = "";
      this.form.shipping_address.region = "";
      let selectedCountry = this.countries.filter(function (country) {
        return country.two_letter_abbreviation == event.target.value;
      });
      if (selectedCountry.length) {
        this.regions = selectedCountry[0].available_regions;
        if (this.regions) {
          this.form.shipping_address.region =
            selectedCountry[0].available_regions[0].code;
        }
      } else {
        this.regions = {};
      }
    },
  },
};
</script>

<style></style>
