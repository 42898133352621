<template>
  <div class="header">
    <div class="header__body">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="header__content text-center">
              <div class="header__image">
                <img src="../assets/images/404.jpg" alt="404" />
              </div>

              <h1 class="header__title h3" v-t="'404-not-found.title'">
                Whoops! You are currently in uncharted waters
              </h1>

              <div class="header__intro text-lg" v-t="'404-not-found.content'">
                <p>This page has gone missing or the URL doesn’t exist.</p>
              </div>

              <div class="header__actions actions">
                <div class="btn-group d-flex flex-wrap justify-content-center">
                  <router-link
                    :to="{ name: 'Home' }"
                    class="btn btn-secondary"
                    href="#"
                  >
                    <span v-t="'404-not-found.button'">Return home</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    if (!location.href.includes("/not-found"))
      location.href = "/" + this.$i18n.locale + "/not-found";
  },
  mounted: function () {
    /*document
      .querySelector('meta[name="robots"]')
      .setAttribute("content", "noindex,nofollow");*/
  },
  methods: {},
};
</script>
