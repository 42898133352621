<template>
  <li class="list-item">
    <div class="row">
      <div class="col-3 col-sm-2">
        <div class="list-item__visuals">
          <div class="list-item__media media ratio ratio--square">
            <router-link
              :to="{
                name: 'ProductSingle',
                params: {
                  locale: this.$i18n.locale,
                  productSlug: product.url_key,
                },
              }"
              class="media__wrapper"
              tabindex="-1"
              :title="product.name"
            >
              <img :src="product.image.url" :alt="product.name" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-9 col-sm-10">
        <div class="list-item__body">
          <div class="row d-flex">
            <div class="list-item__content product-item__content col-md-6">
              <div class="list-item__title product-item__title">
                <router-link
                  :to="{
                    name: 'ProductSingle',
                    params: {
                      locale: this.$i18n.locale,
                      productSlug: product.url_key,
                    },
                  }"
                  tabindex="-1"
                  :title="product.name"
                >
                  <span v-html="product.name"></span>
                </router-link>
              </div>
              <div class="list-item__label product-item__sku">
                <span> SKU: {{ product.sku }} </span>
              </div>
              <div
                class="list-item__desc product-item__desc"
                v-if="product.short_description"
                v-html="product.short_description.html"
              ></div>
              <!-- <div class="list-item__notice alert bg-danger text-white">
                <p>
                  This product is not available in the requested quantity.<br />
                  The item will be backordered.
                </p>
              </div> -->
            </div>
            <div class="product-item__quantity col-md-4">
              <div class="form-group">
                <label for="pw" v-t="'miscellaneous.labels.quantity'"
                  >Quantity</label
                >
                <div class="custom-control custom-number quantity">
                  <!-- @TODO Update will now directly affect the state, this is not allowed -->
                  <input
                    type="number"
                    class="form-control quantity--input"
                    min="1"
                    name="quantity"
                    v-model="cart_item_quantity"
                  />
                  <button
                    class="btn btn-quantity--plus"
                    @click="quantityIncrement"
                  >
                    <i class="icon icon-keyboard_arrow_up"></i>
                  </button>
                  <button
                    class="btn btn-quantity--minus"
                    @click="quantityDecrement"
                  >
                    <i class="icon icon-keyboard_arrow_down"></i>
                  </button>
                </div>
              </div>
              <!-- {{ product }} -->
              <ProductStock
                :stock="product.customer_stock"
                v-if="
                  product.customer_stock != null &&
                    product.customer_stock != -1 &&
                    isLoggedIn
                "
              ></ProductStock>
            </div>
            <div
              class="product-item__meta col-md-2 d-flex flex-column text-right"
            >
              <ProductPrice
                v-if="isLoggedIn && product.customer_price != null"
                :price="product.customer_price.regular_price"
              ></ProductPrice>

              <div class="product-item__actions mt-auto">
                <button
                  class="btn btn btn-sm btn-empty btn-empty-primary"
                  @click="removeProduct"
                >
                  <span v-t="'miscellaneous.labels.remove'">Remove</span>
                  <i class="icon icon-remove_circle_outline"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import ProductPrice from "./ProductPrice.vue";
import ProductStock from "./ProductStock.vue";

import { mapActions, mapGetters } from "vuex"; //

export default {
  components: {
    ProductPrice,
    ProductStock
  },
  props: {
    product: {
      type: Object
    }
    // cartItemId: {
    //   type: Number
    // }
  },
  data() {
    return {
      cart_item_quantity: 0
    };
  },
  created() {
    this.cart_item_quantity = this.quote[this.product.sku];
  },

  updated() {
    this.cart_item_quantity = this.quote[this.product.sku];
  },
  computed: {
    ...mapGetters(["quote", "isLoggedIn"])
  },
  watch: {
    cart_item_quantity(cart_item_quantity) {
      if (cart_item_quantity < 1) this.cart_item_quantity = 1;
      this.updateQuantity();
    }
  },
  methods: {
    ...mapActions(["updateProductFromQuote", "removeProductFromQuote"]),
    removeProduct() {
      this.removeProductFromQuote(this.product.sku);
    },
    quantityIncrement() {
      this.cart_item_quantity++;
    },
    quantityDecrement() {
      if (this.cart_item_quantity === 1) return;
      this.cart_item_quantity--;
    },
    updateQuantity() {
      this.updateProductFromQuote({
        sku: this.product.sku,
        quantity: Number(this.cart_item_quantity)
      });
    }
  }
};
</script>
