<template>
  <div
    class="carousel carousel--product slide"
    id="carousel-product"
    v-if="product.media_gallery.length > 0"
  >
    <div class="carousel-top">
      <div class="carousel-inner">
        <div
          v-for="(image, i) in product.media_gallery"
          :key="i"
          :class="{ active: i === 0 }"
          class="carousel-item media ratio"
        >
          <div class="media__wrapper">
            <img :src="image.url" :alt="image.alt ? image.alt : product.name" />
          </div>
        </div>
      </div>

      <!-- Arrows -->
      <a
        class="carousel-control carousel-control-prev"
        href="#carousel-product"
        role="button"
        data-slide="prev"
      >
        <span
          class="carousel-control-icon carousel-control-prev-icon"
          aria-hidden="true"
        >
          <i class="icon icon-keyboard_arrow_left"></i>
        </span>
        <span class="sr-only" v-t="'carousel.previous'">Previous</span>
      </a>

      <a
        class="carousel-control carousel-control-next"
        href="#carousel-product"
        role="button"
        data-slide="next"
      >
        <span
          class="carousel-control-icon carousel-control-next-icon"
          aria-hidden="true"
        >
          <i class="icon icon-keyboard_arrow_right"></i>
        </span>
        <span class="sr-only" v-t="'carousel.next'">Next</span>
      </a>

      <div class="carousel-pagination-wrap">
        <div class="carousel-pagination text-sm">
          <span class="carousel-pagination__current"></span>
          <span class="carousel-pagination__seperator"> / </span>
          <span class="carousel-pagination__total"></span>
        </div>
      </div>
    </div>

    <!-- Thumbnails -->
    <ol v-if="product.media_gallery.length > 1" class="carousel-indicators">
      <li
        v-for="(image, i) in product.media_gallery"
        :key="i"
        :class="{ active: i === 0 }"
        class="carousel-indicator media ratio"
        role="button"
        data-target="#carousel-product"
        :data-slide-to="i"
      >
        <div class="media__wrapper">
          <img
            :src="image.url"
            :alt="
              image.alt
                ? `Slide ${i + 1} thumbnail: ${image.alt}`
                : `Slide ${i + 1} thumbnail: ${product.name}`
            "
          />
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Carousel from "bootstrap.native/dist/components/carousel-native.esm";

export default {
  name: "CarouselProduct",

  computed: {
    ...mapGetters(["singleProduct"]),
    product: function() {
      return this.singleProduct[this.$route.params.productSlug];
    }
  },

  mounted() {
    this.init();

    if (this.product.media_gallery && this.product.media_gallery.length > 0) {
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", this.product.media_gallery[0].url);
    }
  },

  methods: {
    init() {
      // Run Bootstrap's carousel initialization function before doing anything else.
      const carousel = document.querySelector("#carousel-product");
      if (carousel) {
        new Carousel(carousel, {
          keyboard: true,
          touch: true,
          interval: false
        });

        const slides = carousel.querySelectorAll(".carousel-item");
        const thumbnails = carousel.querySelectorAll(".carousel-indicator");

        // if (slides.length !== thumbnails.length) {
        //   throw new Error(
        //     "Amount of slides isn't equal to the amount of thumbnails."
        //   );

        //   // return;
        // }

        this.updatePagination(carousel);

        // Events
        carousel.addEventListener("slid.bs.carousel", () => {
          // console.log("Carousel slide has slid.");

          this.updatePagination(carousel);
        });
      }
    },

    updatePagination(carousel) {
      const slides = carousel.querySelectorAll(".carousel-item");
      const slidesArr = Array.from(slides);

      const activeSlide = carousel.querySelector(".carousel-item.active");
      const activeSlideNo = slidesArr.indexOf(activeSlide) + 1;

      const elTotalSlideNo = carousel.querySelector(
        ".carousel-pagination__total"
      );
      const elCurrentSlideNo = carousel.querySelector(
        ".carousel-pagination__current"
      );

      if (parseInt(elTotalSlideNo.innerHTML) !== slides.length) {
        elTotalSlideNo.innerHTML = slides.length;
      }
      elCurrentSlideNo.innerHTML = activeSlideNo;

      // console.log("Carousel pagination has been updated.");
    }
  }
};
</script>

<style lang="scss" scoped>
.media__wrapper {
  pointer-events: none;

  img {
    pointer-events: none;
  }
}
</style>
