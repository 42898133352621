<template>
  <section class="section">
    <div class="container">
      <div class="pagination d-flex justify-content-center" v-if="totalCount">
        <button
          class="btn btn-icon-only btn-secondary pagination__page pagination__page--prev"
          @click="pageNumber(pagination.currentPage - 1)"
          :class="{
            invisible: pagination.currentPage === 1
          }"
        >
          <i class="icon icon-keyboard_arrow_left"></i>
        </button>

        <!-- First page -->
        <button
          class="btn pagination__page pagination__page--page"
          @click="pageNumber(1)"
          v-if="pagination.currentPage > 1"
        >
          1
        </button>

        <!-- Dots -->
        <span
          class="pagination__page pagination__page--dots"
          v-if="pagination.currentPage - 4 >= 0"
          >...</span
        >

        <!-- Page before current -->
        <button
          class="btn pagination__page pagination__page--page"
          @click="pageNumber(pagination.currentPage - 1)"
          v-if="pagination.currentPage > 2"
        >
          {{ pagination.currentPage - 1 }}
        </button>

        <span class="pagination__page is-active">
          {{ pagination.currentPage }}
        </span>

        <!-- Page after current -->
        <button
          class="btn pagination__page pagination__page--page"
          @click="pageNumber(pagination.currentPage + 1)"
          v-if="pagination.currentPage < numberOfPages - 1"
        >
          {{ pagination.currentPage + 1 }}
        </button>

        <!-- Dots -->
        <span
          class="pagination__page pagination__page--dots"
          v-if="numberOfPages - pagination.currentPage > 2"
          >...</span
        >

        <!-- Last page -->
        <button
          class="btn pagination__page pagination__page--page"
          @click="pageNumber(numberOfPages)"
          v-if="pagination.currentPage < numberOfPages"
        >
          {{ numberOfPages }}
        </button>

        <button
          class="btn btn-icon-only btn-secondary pagination__page pagination__page--next"
          @click="pageNumber(pagination.currentPage + 1)"
          :class="{
            invisible:
              pagination.currentPage === Math.ceil(totalCount / pageSize)
          }"
        >
          <i class="icon icon-keyboard_arrow_right"></i>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    pagination: Object,
    totalCount: Number
  },
  data() {
    return {
      numberOfPages: 0,
      pageSize: 24
    };
  },
  created() {
    this.numberOfPages = Math.ceil(this.totalCount / this.pageSize);
  },
  methods: {
    pageNumber: function(value) {
      this.$emit("update:page", value);
      // console.log("Pagination", this.pagination);
    }
  }
};
</script>

<style lang="css" scoped></style>
