<template>
  <section
    class="section cards cards--cat-prod mb-0"
    v-if="!isFetchingCategories || Object.keys(categories).length > 0"
  >
    <div class="section__header section__header--left mb-3">
      <h4 class="section__title">
        {{ category ? category.name : $t("products.title") }}
      </h4>
    </div>
    <div class="section__body cards__body">
      <div
        class="product-category hentry text-sm mb-5"
        v-if="category && category.description"
      >
        <div v-html="getCategorySummary(category.description)"></div>

        <div
          class="product-category__action text-center mt-5"
          v-if="
            getCategorySummary(category.description).length &&
              getCategoryDescription(category.description).length &&
              this.descExpanded == false
          "
        >
          <button
            class="btn btn-sm btn-empty btn-empty-primary"
            @click="this.descExpanded = true"
          >
            <span v-t="'miscellaneous.buttons.show-more'">
              Show more
            </span>
            <i class="icon icon-keyboard_arrow_down"></i>
          </button>
        </div>
        <div
          v-html="getCategoryDescription(category.description)"
          v-if="
            getCategoryDescription(category.description).length &&
              this.descExpanded == true
          "
        ></div>

        <div
          class="product-category__action text-center mt-5"
          v-if="
            getCategoryDescription(category.description).length &&
              this.descExpanded == true
          "
        >
          <button
            class="btn btn-sm btn-empty btn-empty-primary"
            @click="this.descExpanded = false"
          >
            <span v-t="'miscellaneous.buttons.show-less'">
              Show less
            </span>
            <i class="icon icon-keyboard_arrow_up"></i>
          </button>
        </div>
        <hr
          v-if="
            !category ||
              (category &&
                category.children &&
                Object.keys(category.children).length > 0)
          "
        />
      </div>
      <div
        v-if="
          category &&
            (!category.children || Object.keys(category.children).length == 0)
        "
        v-cloak
      >
        <ProductList
          :categorySlug="categorySlug"
          :categoryId="category.id"
          :productCount="category.product_count"
        >
        </ProductList>
      </div>

      <div class="row row--col-margin-medium" v-else>
        <div
          class="col-6 col-md-4 col-lg-3"
          v-for="(category, i) in categories"
          :key="i"
        >
          <Category :category="category" :index="category.url_path"> </Category>
        </div>
      </div>
    </div>
  </section>
  <section
    class="section cards cards--cat-prod mb-0"
    v-else-if="isFetchingCategories"
  >
    <div class="container">
      <ContentLoader viewBox="0 0 1180 130" style="width: 100%;">
        <rect x="0" y="0" width="420" height="33px" />

        <rect x="0" y="50" width="900" height="20" />
        <rect x="0" y="76" width="800" height="20" />


      </ContentLoader>

      <CategoryLoader :categories="4" :classes="'col-6 col-md-4 col-lg-3'"></CategoryLoader>


    </div>

  </section>

  <section v-else>
    <div class="container">
      <span v-t="'miscellaneous.labels.no-results'">
        We couldn't find any anything.
      </span>
    </div>
  </section>

</template>

<script>
import { ContentLoader } from "vue-content-loader";
import Category from "./../../components/Categories/Category.vue";
import ProductList from "./ProductList.vue";
import CategoryLoader from "../../components/Categories/CategoryLoader.vue";
// import BreadcrumbsManager from "./../../mixins/BreadcrumbManager";
import { mapGetters } from "vuex";

export default {
  components: {
    Category,
    ProductList,
    CategoryLoader,
    ContentLoader
  },
  // mixins: [BreadcrumbsManager],

  props: {
    categorySlug: {
      type: String
    },
    subCategorySlug: {
      type: String
    }
  },
  data: () => {
    return {
      products: [],
      descExpanded: false
    };
  },
  watch: {
    isFetchingCategories: function() {
      if (this.category) {
        // Title
        if (this.category.meta_title) {
          document.title = this.category.meta_title + " - VETUS";
          document
            .querySelector('meta[property="og:title"]')
            .setAttribute("content", this.category.meta_title);
        } else {
          document.title = this.category.name + " - VETUS";
          document
            .querySelector('meta[property="og:title"]')
            .setAttribute("content", this.category.name);
        }

        // Meta description
        if (this.category.meta_description) {
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", this.category.meta_description);
          document
            .querySelector('meta[property="og:description"]')
            .setAttribute("content", this.category.meta_description);
        } else {
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", "");
          document
            .querySelector('meta[property="og:description"]')
            .setAttribute("content", "");
        }
      }

      if (this.is404Category() || this.is404SubCategory()) {
        this.$router.replace({
          name: "NotFound",
          params: { pathMatch: this.$route.fullPath.substring(1) }
        });
      }
    }
  },
  methods: {
    getCategorySummary: function(value) {
      let items = value.split("<p>[more]</p>");
      if (items.length == 1) {
        return value;
      }
      let desc = items[0];
      return desc;
    },
    getCategoryDescription: function(value) {
      let items = value.split("<p>[more]</p>");
      if (items.length == 1) {
        return "";
      }
      let desc = items[1];
      return "<br>" + desc;
    },
    is404Category() {
      return (
        Object.keys(this.$store.getters.categories).length > 0 &&
        this.categorySlug &&
        this.$store.getters.categories[this.categorySlug] === undefined
      );
    },
    is404SubCategory() {
      return (
        Object.keys(this.$store.getters.categories).length > 0 &&
        this.subCategorySlug &&
        this.categorySlug &&
        (this.$store.getters.categories[this.categorySlug] === undefined ||
          this.$store.getters.categories[this.categorySlug].children[
            this.subCategorySlug
          ] === undefined)
      );
    }
  },
  computed: {
    ...mapGetters(["isFetchingCategories"]),
    categories: function() {
      if (
        this.categorySlug &&
        this.$store.getters.categories[this.categorySlug] !== undefined
      ) {
        // this.replaceeBreadcrumbs();
        return this.$store.getters.categories[this.categorySlug].children;
      } else {
        // this.replaceeBreadcrumbs();
        return this.$store.getters.categories;
      }
    },
    category: function() {
      if (
        this.subCategorySlug &&
        this.$store.getters.categories[this.categorySlug] !== undefined
      ) {
        return this.$store.getters.categories[this.categorySlug].children[
          this.subCategorySlug
        ];
      } else if (
        this.categorySlug &&
        this.$store.getters.categories[this.categorySlug] !== undefined
      ) {
        return this.$store.getters.categories[this.categorySlug];
      } else {
        return null;
      }
    }
    // nybreadcrumbsbringsalltheboystotheyard: function() {
    // let category = this.category;
    // if (category != null) {
    //   this.replaceBreadcrumbs();
    // }
    // return "Maar echt, hoor..";
    // }
  }
};
</script>

<style lang="css" scoped></style>
