<template>
  <section
    class="section products list list--products"
    v-if="!isFetchingQuotes && quoteProducts && quoteProducts.total_count > 0"
  >
    <div class="container">
      <ul class="list-view list-inline">
        <template v-for="(product, i) in quoteProducts.items" :key="i">
          <ProductListview2quote :product="product"></ProductListview2quote>
          <!-- :cartItemId="parseFloat(product.sku)" -->
        </template>
      </ul>
      <div class="row">
        <div class="col-lg-5 offset-lg-7">
          <div class="box">
            <div class="box__body bg-white">
              <div class="box__content p-0">
                <div class="text-right">
                  <router-link
                    :to="{ name: 'Choose a dealer' }"
                    class="btn btn-secondary ml-auto"
                    v-if="currentRouteName == 'Quote' && !isLoggedIn"
                  >
                    <span v-t="'shop.quote.proceed-dealer'"
                      >Continue to choose a dealer</span
                    >
                    <i class="icon icon-keyboard_arrow_right"></i>
                  </router-link>

                  <router-link
                    :to="{ name: 'Contact info' }"
                    class="btn btn-secondary ml-auto"
                    v-if="currentRouteName == 'Quote' && isLoggedIn"
                  >
                    <span v-t="'shop.quote.proceed'"
                      >Proceed to contact info</span
                    >
                    <i class="icon icon-keyboard_arrow_right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProductListview2quote from "../../components/Products/ProductListview2quote.vue";

export default {
  components: {
    ProductListview2quote
  },
  created() {
    this.fetchQuoteProducts();
  },
  methods: {
    ...mapActions(["fetchQuoteProducts", "setQuoteDealer"])
  },
  computed: {
    ...mapGetters([
      "quoteProducts",
      "quote",
      "quoteDealer",
      "isFetchingQuotes",
      "isLoggedIn"
    ]),
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>

<style lang="css" scoped></style>
